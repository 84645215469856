export enum Role {
  Administrations,
  APP_PPCreate,
  APP_PPDashBoard,
  APP_PPGenKey,
  APP_PPTestConfiguration,
  APP_PPUpdate,
  APP_PPUpdateStatus,
  APP_PPView,
  BM_BUConfigService,
  BM_BUCreate,
  BM_BUUpdate,
  BM_BUView,
  CP_CardCreate,
  CP_CardUpdate,
  CP_CardUpdateStatus,
  CP_CardView,
  GS_BUTestPage,
  GS_GlobalScreenCreate,
  GS_GlobalScreenUpdate,
  GS_GlobalScreenUpdateMethod,
  GS_GlobalScreenView,
  OPS_CacheManagement,
  PM_PolicyCreate,
  PM_PolicyPublish,
  PM_PolicyUpdate,
  PM_PolicyView,
  RM_RuleCreate,
  RM_RuleUpdate,
  RM_RuleUpdateStatus,
  RM_RuleView,
  RP_OPS,
  RP_PushProvisioning,
  RP_View,
  TM_FullAccView,
  TM_TokenActive,
  TM_TokenDelete,
  TM_TokenHis,
  TM_TokenSuspend,
  TM_TokenUnSuspend,
  TM_TokenView_MC,
  TM_TokenView,
  TM_TrxnSearch,
  UM_ResetPassword,
  UM_UserCreate,
  UM_UserExport,
  UM_UserUpdateBU,
  UM_UserUpdateProfile,
  UM_UserUpdateRole,
  UM_UserUpdateStatus,
  UM_UserView,
  OPS_Send_PP_Report,
}

export type RoleCode = keyof typeof Role;
